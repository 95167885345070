import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

const NavigationBar = () => {
    const [scrolled, setScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false); // État pour gérer l'ouverture/fermeture du menu

    // Gérer le changement de couleur de la navbar lors du défilement
    const handleScroll = () => {
        const isScrolled = window.scrollY > 50;
        setScrolled(isScrolled);
    };

    // Fonction pour basculer l'état du menu hamburger
    const toggleMenu = () => {
        setMenuOpen(prevMenuOpen => !prevMenuOpen);
    };

    // Fonction pour fermer le menu
    const closeMenu = () => {
        setMenuOpen(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Fonction de défilement fluide vers la section "Services"
    const scrollToServices = (event) => {
        event.preventDefault(); // Empêche le comportement par défaut du lien
        const servicesSection = document.getElementById("services");

        // Si on est déjà sur la page d'accueil
        if (window.location.pathname === "/home#service" || window.location.pathname === "home#service") {
            servicesSection.scrollIntoView({ behavior: "smooth" });
        } else {
            // Naviguer vers la page d'accueil et faire défiler vers la section Services
            window.location.href = "/home#services"; // Redirection vers la page d'accueil
            setTimeout(() => {
                servicesSection.scrollIntoView({ behavior: "smooth" });
            }, 500); // Attendre un peu avant de faire défiler la page
        }
    };

    return (
        <header>

            <input className="menu-icon" type="checkbox" id="menu-icon" checked={menuOpen} onChange={toggleMenu} />
            <label htmlFor="menu-icon"></label>

            <nav className="nav">
                <ul className="pt-5">
                    <li><Link to="/home" onClick={closeMenu}>Accueil</Link></li>
                    <li><Link to="/about" onClick={closeMenu}>À propos</Link></li>
                    <li><a href="#services" onClick={(e) => { scrollToServices(e); closeMenu(); }}>Services</a></li>
                    <li><Link to="/contact" onClick={closeMenu}>Contact</Link></li>
                    <li><Link to="/blog" onClick={closeMenu}>Blog</Link></li>
                    <li><Link to="/faq" onClick={closeMenu}>FAQ</Link></li>
                </ul>
            </nav>

        </header>
    );
};

export default NavigationBar;