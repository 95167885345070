import React from 'react';
import { Link } from 'react-router-dom';
import './ContactSidebar.css';

const ContactSidebar = () => {
  return (
    <div className="contact-sidebar">
      <div className="contact-item">
        <Link to="/contact" className="contact-link">
          <div className="contact-icon">
            <i className="fas fa-phone-alt"></i>
          </div>
          <span>Appeler</span>
        </Link>
      </div>
      <div className="contact-item">
        <Link to="/contact" className="contact-link">
          <div className="contact-icon">
            <i className="fas fa-envelope"></i>
          </div>
          <span>Email</span>
        </Link>
      </div>
      <div className="contact-item">
        <Link to="/contact" className="contact-link">
          <div className="contact-icon">
            <i className="fas fa-comment-dots"></i>
          </div>
          <span>Contact</span>
        </Link>
      </div>
    </div>
  );
}

export default ContactSidebar;