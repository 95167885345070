import React from "react";
import { Link } from 'react-router-dom';
import "./ServicesSection.css";
import img1 from "../components/img/dc.jpg";
import img2 from "../components/img/ww.jpg";
import img3 from "../components/img/ETT.jpg";
import img4 from "./img/knlg.jpg";
import logo from "../components/img/logoC.png"; 

function ServicesSection() {
    const sections = [
        {
            title: "Click Design",
            description: "Spécialisé dans l'architecture et la construction, Click Design offre des solutions innovantes et durables pour tous vos projets de construction.",
            img: img1,
            link: "/click-design",
        },
        {
            title: "Click Digital",
            description: "Fournisseur de services numériques, Click Digital conçoit des solutions technologiques avancées pour améliorer votre présence en ligne.",
            img: img2,
            link: "/click-digital",
        },
        {
            title: "Click Entertainment",
            description: "Click Entertainment se spécialise dans la création visuelle et audiovisuelle, offrant des solutions adaptées aux besoins de ses clients.",
            img: img3,
            link: "/click-entertainment",
        },
        {
            title: "Click Knowledge",
            description: "Outil de suivi 360° de l'étudiant et de son bien-être : Click The Light Of Knowledge améliore l'éducation avec des solutions numériques et un accompagnement personnalisé pour soutenir le développement académique et personnel des étudiants.",
            img: img4,
            link: "/click-knowledge",
        },
    ];

    return (
        <section id="services" className="services">
            {/* Section Logo */}
            <div id="logo-section" className="logo-section">
                <img src={logo} alt="Logo" className="logo-image" />
                <img src={logo} alt="Logo" className="bouncing-logo2" />
            </div>

            {/* Titre animé */}
            <div className="animated-title">
                <svg viewBox="0 0 200 10"> 
                    <symbol id="s-text">
                        <text x="50%" y="50%" textAnchor="middle" className="text-line">
                            Nos Entreprises
                        </text>
                    </symbol>
                    <g className="text-group">
                        <use xlinkHref="#s-text" className="text-copy"></use>
                        <use xlinkHref="#s-text" className="text-copy"></use>
                        <use xlinkHref="#s-text" className="text-copy"></use>
                    </g>
                </svg>
            </div>

            {/* Liste des sections */}
            <div className="service-sections">
                {sections.map((section, index) => (
                    <div
                        className={`service-section ${index % 2 === 0 ? "" : "reverse"}`}
                        key={index}
                    >
                        <Link to={section.link} className="image-wrapper">
                            <div
                                className="image"
                                style={{ backgroundImage: `url(${section.img})` }}
                            ></div>
                        </Link>
                        <div className="text">
                            <Link to={section.link} className="section-link">
                                <h3>{section.title}</h3>
                            </Link>
                            <p>{section.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default ServicesSection;
