import React from 'react';
import './ClickEntertaiment.css';

function ClickEntertaiment() {
    return (
        <div className="click-entertainment">
            <h1>CLICK ENTERTAINMENT</h1>
            
            <section className="section">
                <h2>1. PRÉSENTATION GÉNÉRALE</h2>
                <p>
                    CLICK ENTERTAINMENT, une entreprise née sous l’égide de VISION CLICK, se
                    positionne comme un acteur majeur dans l’univers de la production audiovisuelle, la
                    photographie et le design graphique.
                    Notre ambition dépasse les frontières du Cameroun : nous nous inscrivons dans une vision
                    panafricaine, où la créativité et l’innovation servent à valoriser les talents et les identités
                    culturelles du continent. À travers nos productions, nous racontons des histoires qui
                    résonnent avec la diversité et la richesse de l’Afrique, en mettant en avant des récits
                    authentiques et inspirants.
                </p>
            </section>

            <section className="section">
                <h2>2. VISION, MISSION ET VALEURS</h2>
                <h3>Vision</h3>
                <p>
                    Notre vision est d’émerger comme un leader incontesté et respecté à l’échelle
                    panafricaine dans le domaine de la production de contenus audiovisuels. Nous aspirons à
                    offrir des productions qui incarnent l’innovation, captivent les audiences et inspirent
                    l'engagement à travers des récits authentiques et percutants.
                </p>
                <ul>
                    <li>Mettre en lumière la richesse des cultures africaines</li>
                    <li>Promouvoir les talents africains</li>
                    <li>Valoriser les entreprises africaines et l’innovation locale</li>
                    <li>Créer un impact positif à travers des récits engageants</li>
                </ul>
                <h3>Mission</h3>
                <p>
                    Notre mission est d’apporter une valeur ajoutée exceptionnelle à l’industrie
                    audiovisuelle en Afrique et au-delà, en proposant des services de production et de création
                    de contenu digital de qualité supérieure qui se distinguent par leur authenticité, leur
                    créativité et leur impact.
                </p>
                <ul>
                    <li>Offrir des services de production vidéo, de montage et de création de contenu digital de qualité supérieure</li>
                    <li>Valoriser les récits africains et promouvoir le patrimoine culturel à travers nos productions</li>
                    <li>Accompagner nos clients dans le développement de leur image de marque</li>
                </ul>
                <h3>Valeurs Fondamentales</h3>
                <ul>
                    <li>Qualité & Excellence</li>
                    <li>Créativité & Innovation</li>
                    <li>Engagement & Impact</li>
                    <li>Collaboration & Solidarité</li>
                </ul>
            </section>

            <section className="section">
                <h2>3. OBJECTIFS STRATÉGIQUES</h2>
                <ul>
                    <li>Établir Click Entertainment comme une référence en production audiovisuelle</li>
                    <li>Développer un réseau de créateurs et d’entreprises pour des collaborations</li>
                    <li>Produire des contenus qui participent au rayonnement culturel et économique du continent</li>
                    <li>Créer des partenariats avec des structures pour élargir notre impact</li>
                </ul>
            </section>

            <section className="section">
                <h2>4. SERVICES OFFERTS</h2>
                <h3>🎥 Production Vidéo</h3>
                <ul>
                    <li>Couverture événementielle</li>
                    <li>Réalisation de spots publicitaires et institutionnels pour les marques</li>
                    <li>Production de films documentaires et institutionnels</li>
                    <li>Tournage de films et séries</li>
                    <li>Production de clips vidéo musicaux</li>
                </ul>
                <h3>📸 Photographie Professionnelle</h3>
                <ul>
                    <li>Couverture événementielle</li>
                    <li>Shooting photo professionnel</li>
                    <li>Mise en valeur des créateurs à travers des productions visuelles percutantes</li>
                </ul>
                <h3>🎨 Design Graphique</h3>
                <ul>
                    <li>Conception de logos et d’identités visuelles</li>
                    <li>Création de supports publicitaires modernes</li>
                    <li>Développement d’une esthétique qui sublime l’image des entreprises</li>
                </ul>
            </section>

            <section className="section">
                <h2>5. STRATÉGIE DE DÉVELOPPEMENT</h2>
                <ul>
                    <li>Analyse de Marché</li>
                    <li>Marketing et Promotion</li>
                    <li>Réseautage et Partenariats</li>
                </ul>
            </section>

            <section className="section">
                <h2>6. RESSOURCES ET GESTION OPÉRATIONNELLE</h2>
                <ul>
                    <li>Constitution d’une équipe interne de professionnels</li>
                    <li>Partenariats avec des créateurs indépendants</li>
                    <li>Acquisition de matériel audiovisuel de pointe</li>
                    <li>Adaptation aux besoins du marché</li>
                    <li>Suivi et Évaluation</li>
                </ul>
            </section>

            <section className="section">
                <h2>7. PARTENARIATS ET OPPORTUNITÉS DE COLLABORATION</h2>
                <p>
                    Nous recherchons des partenaires stratégiques dans les domaines suivants :
                    Médias et plateformes digitales souhaitant diffuser des contenus.
                    Marques et entreprises ayant besoin de productions audiovisuelles de qualité.
                    Institutions culturelles et éducatives désirant valoriser le patrimoine africain à
                    travers des productions vidéo et photographiques.
                </p>
            </section>

            <section className="section">
                <h2>8. MODALITÉS DE COLLABORATION ET TARIFICATION</h2>
                <ul>
                    <li>Tarification et Devis</li>
                    <li>Modalités de Paiement</li>
                    <li>Engagement et Confidentialité</li>
                </ul>
            </section>

            <section className="section">
                <h2>9. CONTACT ET INFORMATIONS PRATIQUES</h2>
                <p>
                    📍 Siège social : Yaoundé, Cameroun<br />
                    📞 Téléphone : (+237) 695-98-79-19 / 696-06-56-60 / 674 702 983<br />
                    📧 E-mail : info@unclick24.com<br />
                    🌍 Site web : www.unclick24.com<br />
                    📲 Réseaux sociaux :<br />
                    Tiktok : click.channel<br />
                    Youtube : vision click channel<br />
                    Facebook : click channel<br />
                    Instagram : c.l.i.c.k_channel<br />
                    X : click channel<br />
                    Linkedin : vision click empire
                </p>
            </section>
        </div>
    );
}

export default ClickEntertaiment;