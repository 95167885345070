import React, { useRef, useState, useEffect } from 'react';
import './CompanyVideoSection.css';

function CompanyVideoSection() {
    const videoRef = useRef(null);
    const [volume, setVolume] = useState(0.5);
    const [muted, setMuted] = useState(true);
    const [isControlsVisible, setControlsVisible] = useState(false);
    const [isPlaying, setIsPlaying] = useState(true);  // État pour savoir si la vidéo est en lecture ou en pause
    const [controlTimer, setControlTimer] = useState(null);  // Timer pour la disparition des contrôles

    const handleVolumeChange = (event) => {
        const newVolume = event.target.value;
        setVolume(newVolume);
        videoRef.current.volume = newVolume;
    };

    const toggleMute = () => {
        setMuted(!muted);
        videoRef.current.muted = !muted;
    };

    const skipForward = () => {
        if (videoRef.current) {
            videoRef.current.currentTime += 10; // Avance de 10 secondes
        }
    };

    const skipBackward = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = Math.max(0, videoRef.current.currentTime - 10); // Recule de 10 secondes, sans passer sous 0
        }
    };

    const toggleControls = () => {
        setControlsVisible(true);
        if (controlTimer) {
            clearTimeout(controlTimer);  // Réinitialiser le timer
        }
        const newTimer = setTimeout(() => {
            setControlsVisible(false);
        }, 5000);  // Les contrôles disparaissent après 5 secondes
        setControlTimer(newTimer);
    };

    const togglePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    useEffect(() => {
        // Forcer la lecture automatique dès que le composant est monté
        const playVideo = () => {
            if (videoRef.current) {
                videoRef.current.play().catch((error) => {
                    console.error('Erreur lors de la lecture automatique de la vidéo:', error);
                });
            }
        };
        playVideo();
    }, []);

    return (
        <section className="video-section">
            <div className="video-container" onMouseEnter={toggleControls} onMouseLeave={toggleControls}>
                <div className="video-wrapper">
                    <video
                        className="company-video"
                        ref={videoRef}
                        src={require('./video/v1.mp4')}
                        autoPlay
                        loop
                        muted={muted}  // Commence en sourdine pour forcer la lecture automatique
                        playsInline    // Nécessaire pour les appareils mobiles
                    />
                    
                    {/* Contrôle Play/Pause */}
                    <button 
                        onClick={togglePlayPause} 
                        className="play-pause-btn"
                    >
                        {isPlaying ? '⏸' : '▶️'}  {/* Affiche l'icône appropriée */}
                    </button>

                    {/* Contrôles pour skipper */}
                    <div className={`skip-controls ${isControlsVisible ? 'visible' : ''}`}>
                        <button onClick={skipBackward} className="skip-btn">
                            ⏪ -10s
                        </button>
                        <button onClick={skipForward} className="skip-btn">
                            +10s ⏩
                        </button>
                    </div>

                    {/* Contrôle de volume et mute */}
                    <div className={`volume-control ${isControlsVisible ? 'visible' : ''}`}>
                        <button onClick={toggleMute} className="mute-btn">
                            {muted ? 'Activer le son' : 'Désactiver le son'}
                        </button>
                        <label htmlFor="volume-slider">Volume: </label>
                        <input
                            id="volume-slider"
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={volume}
                            onChange={handleVolumeChange}
                            disabled={muted} // Désactiver si en sourdine
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CompanyVideoSection;
